<template>
    <Form
        :submit="handleSubmit"
        :initialValues="initialValues"
        ref="form"
    >
        <loader :loading="loading" :backdrop="true" />
        <div class="form-narrow">
            <div class="form-section-title">contact information</div>
            <div class="form-row">
                <TextField name="firstName" label="first name*" :validate="required"/>
                <TextField name="lastName" label="last name*" :validate="required"/>
            </div>
            <div class="form-row">
                <TextField name="email" label="email*" :validate="[required, email]"/>
                <PhoneInput name="phone" label="phone" :validate="[usPhoneNumber, required]"/>
            </div>

            <div class="form-section-title items-center mt-10" :style="{paddingBottom: '0.5rem'}">cognito identity</div>
            <div class="mb-6 text-2sm">
              Cognito identity can be found by email, phone or identity id, and linked to the contact. If no existing cognito identity is found then a new one can be created.
            </div>
            <PersonCognitoIdentities :initial-values="initPersonIdentities" @update="updateCognitoIdentities" />
            <ModalFooter :footer="footer" :tertiary="cancel" />
        </div>
    </Form>
</template>
<script>
import TextField from "@/components/form/TextField.vue";
import ModalFooter from '@/components/ui/modals/ModalFooter';
import PhoneInput from "@/components/form/PhoneInput";
import Loader from '@/components/ui/Loader';
import Form from '@/components/form/Form';
import PersonCognitoIdentities from '@/views/auth/raa-devtools/persons/PersonCognitoIdentities';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import ModalNavigation from '@/mixins/ModalNavigation';
import {v4} from "uuid";
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'AddPerson',
  mixins: [NotifyMixin, ModalNavigation, ValidatorMixin],
  components: {
    TextField,
    ModalFooter,
    PhoneInput,
    Loader,
    Form,
    PersonCognitoIdentities
  },
  data() {
    return {
      loading: false,
      application: null,
      person: null,
      footer: {
          primaryButton: 'next step',
          tertiaryButton: 'cancel'
      },
      identityIds: [],
    }
  },
  computed: {
    ...mapGetters({
      getApplication: 'application_service/getApplication',
      currentApplicant: 'application_service/getcurrentApplicant',
    }),
    initialValues() {
      return {id: v4()}
    },
    initPersonIdentities() {
      const { identities = [] } = this.initialValues;
      return identities.map(({ cognitoIdentityId, email, id: identityId, phone, active }) => ({
        cognitoIdentityId,
        email,
        identityId,
        phone: phone ?? '',
        active,
        role: ''
      }));
    }
  },
  methods: {
    ...mapActions({
      setApplication: 'application_service/setApplication',
      setFutureGuarantor: 'application_service/setFutureGuarantor',
      setFutureApplicant: 'application_service/setFutureApplicant',
    }),
    handleSubmit({ email, phone, ...values }) {
        this.loading = true;
        if (phone) {
          const { countryCallingCode } = parsePhoneNumberFromString(phone);
          values.phones = [
            {
              phoneNumber: phone,
              countryCode: countryCallingCode,
            },
          ];
        }
        this.$raaDataProvider.create('persons', {
          data: {
            ...values,
            emails: [{ email }],
            identityIds: this.identityIds,
          }
        })
        .then((data) => {
          if (this.role === 'guarantor') {
            this.setFutureGuarantor({
              ...data,
              applicant_status: 'pending',
              email: data.emails[0].email,
              phone: data.phones[0].phoneNumber,
            });
          } else {
            this.setFutureApplicant({
              ...data,
              applicant_status: 'pending',
              email: data.emails[0].email,
              phone: data.phones[0].phoneNumber,
              guarantor: null,
              is_guarantor_needed: false,
              occupants: [],
              pets: [],
              vehicles: [],
              role: this.role,
              fullName: `${data.firstName} ${data.lastName}`
            });
          }
          
          this.notifySuccess('the person has been succefully created');
          this.$router.push({ name: 'manage_application.create_profile', params: { role: this.$route.params.role } });
        })
        .catch(() => {
          this.loading = false;
          this.notifyError('something went wrong creating the person');
        })
        .finally(() => this.loading = false);
    },
    updateCognitoIdentities(identityIds) {
      this.identityIds = identityIds;
    },
    cancel(e) {
      e.preventDefault();
      this.close();
    },
  },
  beforeMount() {
    if (this.getApplication) this.application = this.getApplication;
    if (this.$route.params.role) this.role = this.$route.params.role;
  }
}
</script>